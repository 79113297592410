<template>
  <el-row class="main content container">
    <el-col :xs="24" :sm="24" :md="12" :lg="12" class="left">
      <section>
        <p>
          ShuangzhaoDB是一个将数据库和流处理相结合的数据平台，能够让客户实时提取和触发对数据的操作。
        </p>
      </section>
      <section>
        <p>
          全球知名组织使用ShuangzhaoDB来构建关键任务应用程序，以实现5G货币化盈利、预防欺诈、客户价值管理，以及其他数字化转型计划。
        </p>
      </section>
      <section>
        <p>
          由于许多ShuangzhaoDB客户是在使用其他技术遇到性能局限后开始与我们建立关系，所以我们通过有效的在线发现会议开始接触，以确定双方是否存在技术适合性。
        </p>
      </section>
      <section>
        <p>一个典型会议需要不到一个小时的时间，内容包括：</p>
      </section>
      <section>
        <ul>
          <li>• 业务概述，数据目标和要求，以及当前或过去的挑战</li>
          <li>• ShuangzhaoDB架构在您特定业务案例中的优势</li>
          <li>
            配置指南，以便我们了解您所拥有的内容并帮助您确定所需的内容。例如，您是否打算利用近端部署模型？
          </li>
          <li>
            • 后续步骤，例如结构化的概念验证，包括如何定义和衡量项目的成功
          </li>
        </ul>
      </section>
      <section>
        <p>
          假设双方在技术适合性方面达成共识，我们将提供一个明确定义的计划，其中包含优先级，重要阶段和成功因素。
        </p>
      </section>
      <section>
        <p>填写表格，开始快速的数据决策旅程。</p>
      </section>
    </el-col>
    <el-col :xs="24" :sm="24" :md="12" :lg="12" class="right">
      <h2 class="title">开始免费试用</h2>
      <p class="abstract">
        完成此表获得定制的免费试用体验，包括与ShuangzhaoDB解决方案专家进行免责通话。
      </p>
      <el-form :model="formData" :rules="rules" ref="form">
        <el-form-item label="姓名" prop="name">
          <el-input
            v-model="formData.name"
            clearable
            @input="clearValidation('name')"
          />
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input
            type="email"
            v-model="formData.email"
            clearable
            @input="clearValidation('email')"
          />
        </el-form-item>
        <el-form-item label="公司名称" prop="companyName">
          <el-input
            v-model="formData.companyName"
            clearable
            @input="clearValidation('companyName')"
          />
        </el-form-item>
        <el-form-item label="职位" prop="jobTitle">
          <el-input
            v-model="formData.jobTitle"
            clearable
            @input="clearValidation('jobTitle')"
          />
        </el-form-item>
        <el-form-item label="行业" prop="industry">
          <el-select v-model="formData.industry" clearable filterable>
            <el-option
              v-for="item in industryOpts"
              :key="item.id"
              :value="item.name"
              :label="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="地区" prop="region">
          <el-select v-model="formData.region" clearable filterable>
            <el-option
              v-for="item in regionOpts"
              :key="item.id"
              :value="item.name"
              :label="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            :loading="submitLoading"
            @click="submitForm"
          >
            提交
          </el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
import axios from "axios";
export default {
  name: "Trail",
  data() {
    return {
      submitLoading: false,
      formData: {
        name: "",
        email: "",
        companyName: "",
        jobTitle: "",
        industry: "",
        region: "",
      },
      rules: {
        name: [{ required: true, message: "请输入您的姓名", trigger: "blur" }],
        email: [
          { required: true, message: "请输入您的邮箱", trigger: "blur" },
          { type: "email", message: "请输入正确的邮箱地址", trigger: "blur" },
        ],
        companyName: [
          { required: true, message: "请输入您的公司名称", trigger: "blur" },
        ],
        jobTitle: [
          { required: true, message: "请输入您的职位", trigger: "blur" },
        ],
        industry: [
          { required: true, message: "请选择您所在的行业", trigger: "change" },
        ],
        region: [
          { required: true, message: "请选择您所在的行业", trigger: "change" },
        ],
      },
      industryOpts: [
        { id: 1, name: "计算机/软件/科技" },
        { id: 2, name: "咨询" },
        { id: 3, name: "金融服务技术" },
        { id: 4, name: "物联网" },
        { id: 5, name: "电信" },
        { id: 6, name: "金融服务或金融科技" },
        { id: 7, name: "广告技术" },
        { id: 8, name: "汽车制造" },
        { id: 9, name: "建筑" },
        { id: 10, name: "企业服务" },
        { id: 11, name: "能源" },
        { id: 12, name: "博彩" },
        { id: 13, name: "政府机构" },
        { id: 14, name: "健康护理" },
        { id: 15, name: "保险" },
        { id: 16, name: "法律机构" },
        { id: 17, name: "生命科学" },
        { id: 18, name: "制造工业" },
        { id: 19, name: "新闻媒体" },
        { id: 20, name: "非盈利组织" },
        { id: 21, name: "零售" },
        { id: 22, name: "运输" },
        { id: 23, name: "旅游/酒店/娱乐" },
        { id: 24, name: "院校/教育" },
        { id: 25, name: "其他" },
      ],
      regionOpts: [
        { id: 1, name: "北京市" },
        { id: 2, name: "天津市" },
        { id: 3, name: "河北省" },
        { id: 4, name: "山西省" },
        { id: 5, name: "内蒙古自治区" },
        { id: 6, name: "辽宁省" },
        { id: 7, name: "吉林省" },
        { id: 8, name: "黑龙江省" },
        { id: 9, name: "上海市" },
        { id: 10, name: "江苏省" },
        { id: 11, name: "浙江省" },
        { id: 12, name: "安徽省" },
        { id: 13, name: "福建省" },
        { id: 14, name: "江西省" },
        { id: 15, name: "山东省" },
        { id: 16, name: "河南省" },
        { id: 17, name: "湖北省" },
        { id: 18, name: "湖南省" },
        { id: 19, name: "广东省" },
        { id: 20, name: "广西壮族自治区" },
        { id: 21, name: "海南省" },
        { id: 22, name: "重庆市" },
        { id: 23, name: "四川省" },
        { id: 24, name: "贵州省" },
        { id: 25, name: "云南省" },
        { id: 26, name: "西藏自治区" },
        { id: 27, name: "陕西省" },
        { id: 28, name: "甘肃省" },
        { id: 29, name: "青海省" },
        { id: 30, name: "宁夏回族自治区" },
        { id: 31, name: "新疆维吾尔自治区" },
        { id: 32, name: "台湾省" },
        { id: 33, name: "香港特别行政区" },
        { id: 34, name: "澳门特别行政区" },
      ],
    };
  },
  methods: {
    clearValidation(prop) {
      this.$refs.form.clearValidate(prop);
    },
    async submitForm() {
      try {
        const isValid = await this.$refs.form.validate();
        if (isValid) {
          this.submitLoading = true;
          const {
            data: { retStatus, masgCode },
          } = await axios.post(
            `https://api.shuangzhaodb.cn/api/customer/sendMail`,
            this.formData
          );
          this.submitLoading = false;
          if (retStatus == "0001") {
            this.$message.success(masgCode);
            this.$refs.form.resetFields();
          } else {
            this.$message.error("提交失败，请稍后重试");
          }
        }
      } catch (error) {
        this.submitLoading = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  margin: 50px auto;
  .left,
  .right {
    padding: 0 50px;
  }
  .left {
    padding-left: 0;
    color: #767676;
    section {
      padding-bottom: 16px;
    }
  }
  .right {
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #efefef;
    box-shadow: 0 0 10px 0 #ccc;
    .el-form .el-form-item {
      &:last-of-type {
        margin-bottom: 0;
      }
      .el-select {
        width: 100%;
      }
    }
  }
}

@media screen and(max-width:991px) {
  .content {
    margin: 20px auto;
    .left {
      padding: 0;
      section {
        text-align: justify;
      }
    }
    .right {
      padding: 20px;
      .title {
        font-size: 20px;
      }
    }
  }
}
</style>